.section1{
    background: #012044;
padding: 4%;
}
.section2{
    background: #8C8C8C;
padding: 4%;
}
.section3{
    background: #F2F2F2;
    padding: 3%;
}
.section4{
    background: #678398;
    padding: 3%;
    color: white;
}
.section5{
    background: #343434;
    padding: 4%;
}