.circle {
    width: 90px;
    height: 90px;
    color: white;
    border-radius: 50%;
    border-color: white;
    border: solid 0.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.circle-text {
    display: flex;
    align-items: center;
}