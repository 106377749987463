.section1{
    padding: 1%;
    background: #FCFCFA;
}
.section2{
    padding: 4%;
    background: #0A2A42;
}
.section3{
    padding: 3%;
}
.section4{
    padding: 6%;
    background: #D6DCE0;
}
.section5{
    background: #F1F1F1;
    padding: 4%;
}