.section1{
    background: linear-gradient(to right, #ff1493, #00bfff);
padding: 2%;
}
.section2{
    padding: 5%;
}
.section3{
    padding: 3%;
    background-color: #7307bc;
    color: white;
}
.section4{
    background: #F1F1F1;
    padding: 4%;
}