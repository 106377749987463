.section1{
    background: #7c97ab;
color: white;
height: fit-content;
}

.section2{
    height: fit-content;
    padding: 5%;
    background: #F1F1F1;
    margin-top: 5%;
}

.image{
    position: absolute;
    left:4%;
    right: -0.2%;
    top: 3%;
}
.section3{
    padding: 5%;
}
.section4{
    background: #678398;
    color: white;
    padding: 7%;
}

.section5{
    padding: 4%;
}
.section6{
    padding: 4%;
    background: #D6DCE0;
}
.section7{
    background: #678398;
    color: white;
    padding: 3%;
}
.section8{
    padding: 4%;
}
.section9{
    background: #F1F1F1;
    padding: 5%;
}
.table{
    width: 40%;
}
.section10{
    background: #D6DCE0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
   padding-top:4%;
}

@media screen and (max-width: 767px) {
    .table{
        width: 80%;
    }
}