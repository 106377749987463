.section1{
    background: #4551A5;
    color: white;
    padding: 4%;
}
.section2{
    padding-bottom: 2%;

}
.section3{
    padding: 5%;
    background: #060A3A;
}
.section4{
    padding: 5%;
}
.section6{
    padding: 4%;
}
.section7{
    background: #D6DCE0;
    padding: 5%;
}