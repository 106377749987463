.section1{
    background: #0A2A42;
    color: white;
    padding: 4%;
}
.section2{
    padding: 4%;
}
.section3{
    padding: 4%;
    background: #F1F1F1;
}