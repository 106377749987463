.section1{
    background: #0A2A42;
    padding: 2%;
}

.section2{
    background: #D6DCE0;
    padding: 4%;
}

.section3{
    background: #0A2A42;
    padding: 3%;
    color: white;
}
.section4{
    background: #FCFCFF;
    padding: 4%;
}
.section5{
    padding: 4%;
}
.section6{
    background: #F1F1F1;
    padding: 4%;
}