.section1{
    padding: 5%;
}
.section2{
    background: #D6DCE0;
    padding-top: 5%;
    padding-bottom: 5%;
}
.section3{
    background: #1E1E1E;
    padding-top: 5%;
    padding-bottom: 5%;
padding-left: 3%;
padding-right: 3%;
    color: white;
}

.section4{
    padding: 5%;
}