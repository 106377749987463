.section1 {
    background: #0D0301;
    height: 600px;
}

.section2{
    display: grid;
    justify-content: center;
}

.section3{
    height: fit-content;
    padding-right: 3%;
    padding-left: 3%;
    padding-bottom: 7%;
    padding-top: 7%;
    color: white;
  background: #1E1E1E;
}
.offerings h3{
line-height: 200%;
}
.section4{
    background: #FFE45E;
    padding: 5% 12%;
}
.section5{
    padding: 3%;
}
.section6{
    padding: 5% 9% 9% 9%;
    background: #009BFF;
    color: white;
}
.section7{
    padding: 5%;
}
.section8{
    padding: 4%;
    background: #343434;
}
.section9{
    background: linear-gradient(360deg, #904230 0%, #FFE45E 210.55%);
color: white;
}